import { Component, Inject, NgZone } from '@angular/core';
import { Platform, NavController, ModalController, AlertController, isPlatform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, AppConfig } from './app.config';
import { Constants } from './models/constants.models';
import { SplashScreen } from '@capacitor/splash-screen';
import { DataProvider } from './providers/data.provider';
import { Router } from '@angular/router'; 
import { MyEvent } from './providers/myevent';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import OneSignal from 'onesignal-cordova-plugin';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { LanguageService } from './providers/translateservice';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  themenote: any;
  rtlSide = "left";
  selectedIndex: any;
  token: string="";
  remoteToken: string;
  //notifications: PushNotificationSchema[] = [];
  paid
  type
  category;
  db = getFirestore()
  languageData: any;

  constructor(@Inject(APP_CONFIG) public config: AppConfig, 
    //public tabs: TabsService,
    private platform: Platform, private navCtrl: NavController,
    private zone: NgZone, public dataProvider: DataProvider,
    public languageService: LanguageService,
    public router: Router, public alertCtrl: AlertController,
    private translate: TranslateService, private myEvent: MyEvent) {
    this.initializeApp();
    this.myEvent.getLanguageObservable().subscribe(value => {
      //this.navCtrl.navigateRoot(['./']);
      this.globalize(value);
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'inmigrausa.com';

        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://devdactic.com', '/details/42']

        // Get the last element with pop()
        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        } 

      });
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      setTimeout(()=> {
        SplashScreen.hide()
      }, 2000)
      // Check if user is logged In

      if (localStorage.getItem('isLoggedIn') === 'true') {
          if(localStorage.getItem('uid')){
            const userRefProvider = doc(this.db, "users", localStorage.getItem('uid'));
            const docSnap = await getDoc(userRefProvider);
            let postItem = docSnap.data();
            this.paid = postItem.paid;
            this.type = postItem.type;
            this.category = postItem.category;
            localStorage.setItem('category', this.category);
            if(this.type === 'consultant' && this.paid === false){
              this.router.navigateByUrl('/package-consultant', { skipLocationChange: true, replaceUrl: true });
            } else if(this.type === 'handyman' && this.paid === false){
              this.router.navigateByUrl('/provider-package', { skipLocationChange: true, replaceUrl: true });
            } else {
              this.router.navigateByUrl('/tabs/home', { skipLocationChange: true, replaceUrl: true });
            }
          }
      // Else navigate to Login Page
      } else {
         this.router.navigateByUrl('/login', { skipLocationChange: true, replaceUrl: true });
      }

      let defaultLang = localStorage.getItem(Constants.KEY_DEFAULT_LANGUAGE);
      this.globalize(defaultLang);
     
      });

      if (Capacitor.getPlatform() === 'ios') {
        console.log('iOS!');
        //this.pushNotification();
        this.oneSignalPush()
      } else if (Capacitor.getPlatform() === 'android') {
        console.log('Android!');
        //this.pushNotification()
        this.oneSignalPush()
      } else {
        console.log('Web!');
      }

     
  }

  oneSignalPush(){
    OneSignal.setAppId('7b25460b-6038-4e1f-bc05-5e61f5372bc8');
    OneSignal.setNotificationOpenedHandler((jsonData)=> {
      console.log('notificationOptionCallback: ' + JSON.stringify(jsonData));
    });
    OneSignal.promptForPushNotificationsWithUserResponse((accepted)=> {
      console.log("User accepted notification: " + accepted)
    });
    OneSignal.getDeviceState((stateChanges)=> {
      console.log("OneSignal getDeviceState: " + JSON.stringify(stateChanges));
      if(stateChanges && stateChanges.hasNotificationPermission){
        console.log("Player ID: " + stateChanges.userId)
        localStorage.setItem('fcm', stateChanges.userId)
      } else {
        console.log("Push Notifications are disabled")
      }
    })
  }


  globalize(languagePriority) {
    //this.translate.setDefaultLang("en");
   // this.setDirectionAccordingly(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
   this.languageService.getLanguageData('en').then(data => {
    this.languageData = data;
    this.translate.setTranslation('en', this.languageData);
    this.translate.setDefaultLang('en');
    let defaultLangCode = this.config.availableLanguages[0].code;
    this.translate.use(languagePriority && languagePriority.length ? languagePriority : defaultLangCode);
  });
  }

  setDirectionAccordingly(lang: string) {
    switch (lang) {
      case 'ar': {
        this.rtlSide = "rtl";
        break;
      }
      default: {
        this.rtlSide = "ltr";
        break;
      }
    }
  }

}
