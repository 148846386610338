import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  /*{
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },*/
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'forums',
    loadChildren: () => import('./forums/forums.module').then( m => m.ForumsPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'phone-auth',
    loadChildren: () => import('./phone-auth/phone-auth.module').then( m => m.PhoneAuthPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'single-post',
    loadChildren: () => import('./single-post/single-post.module').then( m => m.SinglePostPageModule)
  },
  // {
  //   path: 'country-code',
  //   loadChildren: () => import('./country-code/country-code.module').then( m => m.CountryCodePageModule)
  // },
  {
    path: 'set-account',
    loadChildren: () => import('./set-account/set-account.module').then( m => m.SetAccountPageModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then( m => m.EditProfilePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'followers',
    loadChildren: () => import('./followers/followers.module').then( m => m.FollowersPageModule)
  },
  {
    path: 'following',
    loadChildren: () => import('./following/following.module').then( m => m.FollowingPageModule)
  },
  {
    path: 'my-posts',
    loadChildren: () => import('./my-posts/my-posts.module').then( m => m.MyPostsPageModule)
  },
  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'add-quote',
    loadChildren: () => import('./add-quote/add-quote.module').then( m => m.AddQuotePageModule)
  },
  {
    path: 'add-poll',
    loadChildren: () => import('./add-poll/add-poll.module').then( m => m.AddPollPageModule)
  },
  {
    path: 'add-post',
    loadChildren: () => import('./add-post/add-post.module').then( m => m.AddPostPageModule)
  },
  {
    path: 'themes',
    loadChildren: () => import('./themes/themes.module').then( m => m.ThemesPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'forum-post',
    loadChildren: () => import('./forum-post/forum-post.module').then( m => m.ForumPostPageModule)
  },
  {
    path: 'nearby-by',
    loadChildren: () => import('./nearby-by/nearby-by.module').then( m => m.NearbyByPageModule)
  },
  {
    path: 'interest',
    loadChildren: () => import('./interest/interest.module').then( m => m.InterestPageModule)
  },
  {
    path: 'forum-followers',
    loadChildren: () => import('./forum-followers/forum-followers.module').then( m => m.ForumFollowersPageModule)
  },
  {
    path: 'range',
    loadChildren: () => import('./range/range.module').then( m => m.RangePageModule)
  },

  {
    path: 'immigrants',
    loadChildren: () => import('./immigrants/immigrants.module').then( m => m.ImmigrantsPageModule)
  },
  {
    path: 'immigrants/:receiptNum',
    loadChildren: () => import('./immigrants/case-detail/case-detail.module').then( m => m.CaseDetailPageModule)
  },
  {
    path: 'add-case',
    loadChildren: () => import('./immigrants/add-case/add-case.module').then( m => m.AddCasePageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },

  {
    path: 'language',
    loadChildren: () => import('./language/language.module').then( m => m.LanguagePageModule)
  },
  {
    path: 'sets',
    loadChildren: () => import('./sets/sets.module').then( m => m.SetsPageModule)
  },
  {
    path: 'passport',
    loadChildren: () => import('./passport/passport.module').then( m => m.PassportPageModule)
  },
  {
    path: 'examine',
    loadChildren: () => import('./examine/examine.module').then( m => m.ExaminePageModule)
  },
  {
    path: 'consultants',
    loadChildren: () => import('./consultants/consultants.module').then( m => m.ConsultantsPageModule)
  },
  {
    path: 'register-service',
    loadChildren: () => import('./register-service/register-service.module').then( m => m.RegisterServicePageModule)
  },
  {
    path: 'consultant-details/:id',
    loadChildren: () => import('./consultant-details/consultant-details.module').then( m => m.ConsultantDetailsPageModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module').then( m => m.ReviewPageModule)
  },
  {
    path: 'my-service',
    loadChildren: () => import('./my-service/my-service.module').then( m => m.MyServicePageModule)
  },
  {
    path: 'my-service-details',
    loadChildren: () => import('./my-service-details/my-service-details.module').then( m => m.MyServiceDetailsPageModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./booking/booking.module').then( m => m.BookingPageModule)
  },
  {
    path: 'available-time',
    loadChildren: () => import('./available-time/available-time.module').then( m => m.AvailableTimePageModule)
  },
  {
    path: 'my-booking',
    loadChildren: () => import('./my-booking/my-booking.module').then( m => m.MyBookingPageModule)
  },
  {
    path: 'register-option',
    loadChildren: () => import('./register-option/register-option.module').then( m => m.RegisterOptionPageModule)
  },
  {
    path: 'register-consultant',
    loadChildren: () => import('./register-consultant/register-consultant.module').then( m => m.RegisterConsultantPageModule)
  },
  {
    path: 'set-account-consultant',
    loadChildren: () => import('./set-account-consultant/set-account-consultant.module').then( m => m.SetAccountConsultantPageModule)
  },
  {
    path: 'handyman',
    loadChildren: () => import('./handyman/handyman.module').then( m => m.HandymanPageModule)
  },
  {
    path: 'handy-service',
    loadChildren: () => import('./handy-service/handy-service.module').then( m => m.HandyServicePageModule)
  },
  {
    path: 'handy-service-list',
    loadChildren: () => import('./handy-service-list/handy-service-list.module').then( m => m.HandyServiceListPageModule)
  },
  {
    path: 'handys-services-all',
    loadChildren: () => import('./handys-services-all/handys-services-all.module').then( m => m.HandysServicesAllPageModule)
  },
  {
    path: 'handy-details/:id',
    loadChildren: () => import('./handy-details/handy-details.module').then( m => m.HandyDetailsPageModule)
  },
  {
    path: 'handy-booking',
    loadChildren: () => import('./handy-booking/handy-booking.module').then( m => m.HandyBookingPageModule)
  },
  {
    path: 'handy-booking-date',
    loadChildren: () => import('./handy-booking-date/handy-booking-date.module').then( m => m.HandyBookingDatePageModule)
  },
  {
    path: 'handy-address',
    loadChildren: () => import('./handy-address/handy-address.module').then( m => m.HandyAddressPageModule)
  },
  {
    path: 'register-provider',
    loadChildren: () => import('./register-provider/register-provider.module').then( m => m.RegisterProviderPageModule)
  },
  {
    path: 'consultant-phone',
    loadChildren: () => import('./consultant-phone/consultant-phone.module').then( m => m.ConsultantPhonePageModule)
  },
  {
    path: 'handy-phone',
    loadChildren: () => import('./handy-phone/handy-phone.module').then( m => m.HandyPhonePageModule)
  },
  {
    path: 'package-consultant',
    loadChildren: () => import('./package-consultant/package-consultant.module').then( m => m.PackageConsultantPageModule)
  },
  {
    path: 'consultant-certificate',
    loadChildren: () => import('./consultant-certificate/consultant-certificate.module').then( m => m.ConsultantCertificatePageModule)
  },
  {
    path: 'payment-consultants',
    loadChildren: () => import('./payment-consultants/payment-consultants.module').then( m => m.PaymentConsultantsPageModule)
  },
  {
    path: 'paypal-consultant',
    loadChildren: () => import('./paypal-consultant/paypal-consultant.module').then( m => m.PaypalConsultantPageModule)
  },
  {
    path: 'stripe-consultant',
    loadChildren: () => import('./stripe-consultant/stripe-consultant.module').then( m => m.StripeConsultantPageModule)
  },
  {
    path: 'certificate-consultant',
    loadChildren: () => import('./certificate-consultant/certificate-consultant.module').then( m => m.CertificateConsultantPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'consultant-advert-adds',
    loadChildren: () => import('./consultant-advert-adds/consultant-advert-adds.module').then( m => m.ConsultantAdvertAddsPageModule)
  },
  {
    path: 'consultant-coupon-add',
    loadChildren: () => import('./consultant-coupon-add/consultant-coupon-add.module').then( m => m.ConsultantCouponAddPageModule)
  },
  {
    path: 'consultant-wallet',
    loadChildren: () => import('./consultant-wallet/consultant-wallet.module').then( m => m.ConsultantWalletPageModule)
  },
  {
    path: 'provider-phone',
    loadChildren: () => import('./provider-phone/provider-phone.module').then( m => m.ProviderPhonePageModule)
  },
  {
    path: 'phone',
    loadChildren: () => import('./phone/phone.module').then( m => m.PhonePageModule)
  },
  {
    path: 'country',
    loadChildren: () => import('./country/country.module').then( m => m.CountryPageModule)
  },
  {
    path: 'provider-certificate',
    loadChildren: () => import('./provider-certificate/provider-certificate.module').then( m => m.ProviderCertificatePageModule)
  },
  {
    path: 'set-account-provider',
    loadChildren: () => import('./set-account-provider/set-account-provider.module').then( m => m.SetAccountProviderPageModule)
  },
  {
    path: 'provider-package',
    loadChildren: () => import('./provider-package/provider-package.module').then( m => m.ProviderPackagePageModule)
  },
  {
    path: 'provider-payment',
    loadChildren: () => import('./provider-payment/provider-payment.module').then( m => m.ProviderPaymentPageModule)
  },
  {
    path: 'paypal-provider',
    loadChildren: () => import('./paypal-provider/paypal-provider.module').then( m => m.PaypalProviderPageModule)
  },
  {
    path: 'stripe-provider',
    loadChildren: () => import('./stripe-provider/stripe-provider.module').then( m => m.StripeProviderPageModule)
  },
  {
    path: 'my-handyman-service',
    loadChildren: () => import('./my-handyman-service/my-handyman-service.module').then( m => m.MyHandymanServicePageModule)
  },
  {
    path: 'my-service-handyman-details',
    loadChildren: () => import('./my-service-handyman-details/my-service-handyman-details.module').then( m => m.MyServiceHandymanDetailsPageModule)
  },
  {
    path: 'register-service-handyman',
    loadChildren: () => import('./register-service-handyman/register-service-handyman.module').then( m => m.RegisterServiceHandymanPageModule)
  },
  {
    path: 'my-package',
    loadChildren: () => import('./my-package/my-package.module').then( m => m.MyPackagePageModule)
  },
  {
    path: 'handy-review',
    loadChildren: () => import('./handy-review/handy-review.module').then( m => m.HandyReviewPageModule)
  },
  {
    path: 'handyman-advert-adds',
    loadChildren: () => import('./handyman-advert-adds/handyman-advert-adds.module').then( m => m.HandymanAdvertAddsPageModule)
  },
  {
    path: 'handyman-coupon-add',
    loadChildren: () => import('./handyman-coupon-add/handyman-coupon-add.module').then( m => m.HandymanCouponAddPageModule)
  },
  {
    path: 'available-time-handyman',
    loadChildren: () => import('./available-time-handyman/available-time-handyman.module').then( m => m.AvailableTimeHandymanPageModule)
  },
  {
    path: 'add-images',
    loadChildren: () => import('./add-images/add-images.module').then( m => m.AddImagesPageModule)
  },
  {
    path: 'provider-checkout-paypal',
    loadChildren: () => import('./provider-checkout-paypal/provider-checkout-paypal.module').then( m => m.ProviderCheckoutPaypalPageModule)
  },
  {
    path: 'provider-checkout-stripe',
    loadChildren: () => import('./provider-checkout-stripe/provider-checkout-stripe.module').then( m => m.ProviderCheckoutStripePageModule)
  },
  {
    path: 'provider-qrcode',
    loadChildren: () => import('./provider-qrcode/provider-qrcode.module').then( m => m.ProviderQrcodePageModule)
  },
  {
    path: 'consultant-qrcode',
    loadChildren: () => import('./consultant-qrcode/consultant-qrcode.module').then( m => m.ConsultantQrcodePageModule)
  },
  {
    path: 'my-booking-provider',
    loadChildren: () => import('./my-booking-provider/my-booking-provider.module').then( m => m.MyBookingProviderPageModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('./transactions/transactions.module').then( m => m.TransactionsPageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./more/more.module').then( m => m.MorePageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'postdetails/:id',
    loadChildren: () => import('./postdetails/postdetails.module').then( m => m.PostdetailsPageModule)
  },
  {
    path: 'booking-details',
    loadChildren: () => import('./booking-details/booking-details.module').then( m => m.BookingDetailsPageModule)
  },
  {
    path: 'my-booking-details',
    loadChildren: () => import('./my-booking-details/my-booking-details.module').then( m => m.MyBookingDetailsPageModule)
  },
  {
    path: 'add-business',
    loadChildren: () => import('./add-business/add-business.module').then( m => m.AddBusinessPageModule)
  },
  {
    path: 'manaage-business',
    loadChildren: () => import('./manaage-business/manaage-business.module').then( m => m.ManaageBusinessPageModule)
  },
  {
    path: 'my-business',
    loadChildren: () => import('./my-business/my-business.module').then( m => m.MyBusinessPageModule)
  },
  {
    path: 'add-business-images',
    loadChildren: () => import('./add-business-images/add-business-images.module').then( m => m.AddBusinessImagesPageModule)
  },
  {
    path: 'withdraw',
    loadChildren: () => import('./withdraw/withdraw.module').then( m => m.WithdrawPageModule)
  },
  {
    path: 'business-details/:id',
    loadChildren: () => import('./business-details/business-details.module').then( m => m.BusinessDetailsPageModule)
  },
  {
    path: 'provider-details',
    loadChildren: () => import('./provider-details/provider-details.module').then( m => m.ProviderDetailsPageModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./success/success.module').then( m => m.SuccessPageModule)
  },
  {
    path: 'checkout-paypal',
    loadChildren: () => import('./checkout-paypal/checkout-paypal.module').then( m => m.CheckoutPaypalPageModule)
  },
  {
    path: 'checkout-stripe',
    loadChildren: () => import('./checkout-stripe/checkout-stripe.module').then( m => m.CheckoutStripePageModule)
  },
  {
    path: 'handy-modal',
    loadChildren: () => import('./handy-modal/handy-modal.module').then( m => m.HandyModalPageModule)
  },
  {
    path: 'paypal-loading',
    loadChildren: () => import('./paypal-loading/paypal-loading.module').then( m => m.PaypalLoadingPageModule)
  },
  {
    path: 'consultant-profile/:id',
    loadChildren: () => import('./consultant-profile/consultant-profile.module').then( m => m.ConsultantProfilePageModule)
  },
  {
    path: 'consultant/:id',
    loadChildren: () => import('./consultant/consultant.module').then( m => m.ConsultantPageModule)
  },
  {
    path: 'handydetails/:id',
    loadChildren: () => import('./handydetails/handydetails.module').then( m => m.HandydetailsPageModule)
  },
  {
    path: 'quiz-category',
    loadChildren: () => import('./quiz-category/quiz-category.module').then( m => m.QuizCategoryPageModule)
  },
  {
    path: 'quiz-list',
    loadChildren: () => import('./quiz-list/quiz-list.module').then( m => m.QuizListPageModule)
  },
  {
    path: 'quiz-detail',
    loadChildren: () => import('./quiz-detail/quiz-detail.module').then( m => m.QuizDetailPageModule)
  },
  {
    path: 'quiz-test',
    loadChildren: () => import('./quiz-test/quiz-test.module').then( m => m.QuizTestPageModule)
  },
  {
    path: 'quiz-paypal',
    loadChildren: () => import('./quiz-paypal/quiz-paypal.module').then( m => m.QuizPaypalPageModule)
  },
  {
    path: 'quiz-stripe',
    loadChildren: () => import('./quiz-stripe/quiz-stripe.module').then( m => m.QuizStripePageModule)
  },
  {
    path: 'test-finish',
    loadChildren: () => import('./test-finish/test-finish.module').then( m => m.TestFinishPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'note-details',
    loadChildren: () => import('./note-details/note-details.module').then( m => m.NoteDetailsPageModule)
  },
  {
    path: 'rankings',
    loadChildren: () => import('./rankings/rankings.module').then( m => m.RankingsPageModule)
  },
  {
    path: 'ranking-details',
    loadChildren: () => import('./ranking-details/ranking-details.module').then( m => m.RankingDetailsPageModule)
  },
  {
    path: 'checkout-paypal-handyman',
    loadChildren: () => import('./checkout-paypal-handyman/checkout-paypal-handyman.module').then( m => m.CheckoutPaypalHandymanPageModule)
  },
  {
    path: 'checkout-stripe-handyman',
    loadChildren: () => import('./checkout-stripe-handyman/checkout-stripe-handyman.module').then( m => m.CheckoutStripeHandymanPageModule)
  },
  {
    path: 'handyman-loading',
    loadChildren: () => import('./handyman-loading/handyman-loading.module').then( m => m.HandymanLoadingPageModule)
  },
  {
    path: 'booking-details-handyman',
    loadChildren: () => import('./booking-details-handyman/booking-details-handyman.module').then( m => m.BookingDetailsHandymanPageModule)
  },
  {
    path: 'my-booking-provider-details',
    loadChildren: () => import('./my-booking-provider-details/my-booking-provider-details.module').then( m => m.MyBookingProviderDetailsPageModule)
  },
  {
    path: 'collaborator-details',
    loadChildren: () => import('./collaborator-details/collaborator-details.module').then( m => m.CollaboratorDetailsPageModule)
  },
  {
    path: 'collab-details',
    loadChildren: () => import('./collab-details/collab-details.module').then( m => m.CollabDetailsPageModule)
  },
  {
    path: 'add-article',
    loadChildren: () => import('./add-article/add-article.module').then( m => m.AddArticlePageModule)
  },
  {
    path: 'details',
    loadChildren: () => import('./details/details.module').then( m => m.DetailsPageModule)
  },
  {
    path: 'review-client',
    loadChildren: () => import('./review-client/review-client.module').then( m => m.ReviewClientPageModule)
  },
  {
    path: 'review-consultant',
    loadChildren: () => import('./review-consultant/review-consultant.module').then( m => m.ReviewConsultantPageModule)
  },
  {
    path: 'my-service-collab',
    loadChildren: () => import('./my-service-collab/my-service-collab.module').then( m => m.MyServiceCollabPageModule)
  },
  {
    path: 'register-service-collaborator',
    loadChildren: () => import('./register-service-collaborator/register-service-collaborator.module').then( m => m.RegisterServiceCollaboratorPageModule)
  },
  {
    path: 'my-service-collaborator-details',
    loadChildren: () => import('./my-service-collaborator-details/my-service-collaborator-details.module').then( m => m.MyServiceCollaboratorDetailsPageModule)
  },
  {
    path: 'collab-adverts',
    loadChildren: () => import('./collab-adverts/collab-adverts.module').then( m => m.CollabAdvertsPageModule)
  },
  {
    path: 'collab-coupon',
    loadChildren: () => import('./collab-coupon/collab-coupon.module').then( m => m.CollabCouponPageModule)
  },
  {
    path: 'my-business-collab',
    loadChildren: () => import('./my-business-collab/my-business-collab.module').then( m => m.MyBusinessCollabPageModule)
  },
  {
    path: 'add-business-collab',
    loadChildren: () => import('./add-business-collab/add-business-collab.module').then( m => m.AddBusinessCollabPageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'scan-profile',
    loadChildren: () => import('./scan-profile/scan-profile.module').then( m => m.ScanProfilePageModule)
  },
  {
    path: 'add-time',
    loadChildren: () => import('./add-time/add-time.module').then( m => m.AddTimePageModule)
  },
  {
    path: 'time-picker',
    loadChildren: () => import('./time-picker/time-picker.module').then( m => m.TimePickerPageModule)
  },
  {
    path: 'add-time-handyman',
    loadChildren: () => import('./add-time-handyman/add-time-handyman.module').then( m => m.AddTimeHandymanPageModule)
  },
  {
    path: 'collab-profile',
    loadChildren: () => import('./collab-profile/collab-profile.module').then( m => m.CollabProfilePageModule)
  },
  {
    path: 'consult-qrprofile',
    loadChildren: () => import('./consult-qrprofile/consult-qrprofile.module').then( m => m.ConsultQrprofilePageModule)
  },
  {
    path: 'handy-qrprofile/:id',
    loadChildren: () => import('./handy-qrprofile/handy-qrprofile.module').then( m => m.HandyQrprofilePageModule)
  },
  {
    path: 'withdraw-configure',
    loadChildren: () => import('./withdraw-configure/withdraw-configure.module').then( m => m.WithdrawConfigurePageModule)
  },
  {
    path: 'refund',
    loadChildren: () => import('./refund/refund.module').then( m => m.RefundPageModule)
  },
  {
    path: 'cancel-booking',
    loadChildren: () => import('./cancel-booking/cancel-booking.module').then( m => m.CancelBookingPageModule)
  },
  {
    path: 'change-date',
    loadChildren: () => import('./change-date/change-date.module').then( m => m.ChangeDatePageModule)
  },
  {
    path: 'book-details',
    loadChildren: () => import('./book-details/book-details.module').then( m => m.BookDetailsPageModule)
  },
  {
    path: 'previews',
    loadChildren: () => import('./previews/previews.module').then( m => m.PreviewsPageModule)
  },
  {
    path: 'items',
    loadChildren: () => import('./items/items.module').then( m => m.ItemsPageModule)
  },
  {
    path: 'create-items',
    loadChildren: () => import('./create-items/create-items.module').then( m => m.CreateItemsPageModule)
  },
  {
    path: 'item-details',
    loadChildren: () => import('./item-details/item-details.module').then( m => m.ItemDetailsPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'item-payment',
    loadChildren: () => import('./item-payment/item-payment.module').then( m => m.ItemPaymentPageModule)
  },
  {
    path: 'item-paypal',
    loadChildren: () => import('./item-paypal/item-paypal.module').then( m => m.ItemPaypalPageModule)
  },
  {
    path: 'item-stripe',
    loadChildren: () => import('./item-stripe/item-stripe.module').then( m => m.ItemStripePageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'create-combo',
    loadChildren: () => import('./create-combo/create-combo.module').then( m => m.CreateComboPageModule)
  },
  {
    path: 'combo-details',
    loadChildren: () => import('./combo-details/combo-details.module').then( m => m.ComboDetailsPageModule)
  },
  {
    path: 'combo-checkout',
    loadChildren: () => import('./combo-checkout/combo-checkout.module').then( m => m.ComboCheckoutPageModule)
  },
  {
    path: 'combo-paypal',
    loadChildren: () => import('./combo-paypal/combo-paypal.module').then( m => m.ComboPaypalPageModule)
  },
  {
    path: 'combo-stripe',
    loadChildren: () => import('./combo-stripe/combo-stripe.module').then( m => m.ComboStripePageModule)
  },
  {
    path: 'my-orders',
    loadChildren: () => import('./my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  },
  {
    path: 'combo-view',
    loadChildren: () => import('./combo-view/combo-view.module').then( m => m.ComboViewPageModule)
  },
  {
    path: 'package-update-consultant',
    loadChildren: () => import('./package-update-consultant/package-update-consultant.module').then( m => m.PackageUpdateConsultantPageModule)
  },
  {
    path: 'package-update-provider',
    loadChildren: () => import('./package-update-provider/package-update-provider.module').then( m => m.PackageUpdateProviderPageModule)
  },
  {
    path: 'payment-consultants-update',
    loadChildren: () => import('./payment-consultants-update/payment-consultants-update.module').then( m => m.PaymentConsultantsUpdatePageModule)
  },
  {
    path: 'provider-payment-update',
    loadChildren: () => import('./provider-payment-update/provider-payment-update.module').then( m => m.ProviderPaymentUpdatePageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}


