import { Injectable } from '@angular/core';
import { Forum } from '../models/forums';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { 
  Firestore, 
  collectionData, collection, 
  startAfter,
  doc, setDoc, deleteDoc, 
  docSnapshots, 
  query, where, limit, orderBy, updateDoc, docData,  } from '@angular/fire/firestore';
import { User, Reviews, Booking, Rankings, Notifications, Withdraw } from '../models/users';
import { Comments, Post, Resolution, Service, Servicecat } from '../models/post';
import { Chat, ChatHistory } from '../models/chat';
import { Package } from '../models/package';
import { Category, Quiz } from '../models/category';
import { Advert } from '../models/package';
import { Business } from '../models/business';
import { GifProvider } from './gif.provider';
import { environment } from 'src/environments/environment';
import { Setting } from '../models/setting';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Config } from '../models/config';
import { Orders } from '../models/orders';


@Injectable({
  providedIn: 'root'
})
export class DataProvider {


  lastVisible: any;
  querySnapshot:any;
  lastInResponse:any;

  constructor(
    private firestore: Firestore, 
    public httpPlugin: HTTP,
    public http: HttpClient, 
    public gifProvider: GifProvider) {}

    getPackagesConsultant(id: any): Observable<Package[]>{
      const postCollection = collection(this.firestore, 'packages-consultant');
      const queryList = query(postCollection, where('category', '==', id));
      return collectionData(queryList, {idField: 'id'}) as Observable<Package[]>;
      
    }

    getCatById(id: string): Observable<Forum> {
      const document = doc(this.firestore, `categories/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Forum;
        })
      );
    }

  
  
    getRanking(userId: any): Observable<Rankings[]>{
      const noteCollection = collection(this.firestore, 'users', userId, 'rankings');
      const queryList = query(noteCollection, orderBy('date'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(notification => notification as Rankings[])
      );
    }

   
    getPackagesHandyman(id: any): Observable<Package[]>{
      const postCollection = collection(this.firestore, 'packages-handyman');
      const queryList = query(postCollection, where('category', '==', id));
      return collectionData(queryList, {idField: 'id'}) as Observable<Package[]>;
      
    }

    getAdvertByUserId(id: any): Observable<Advert[]>{
      const postCollection = collection(this.firestore, 'adverts');
      const queryList = query(postCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'}) as Observable<Advert[]>;
      
    }

    
    getAdverts(state: any): Observable<Advert[]> {
      const contactsCollection = collection(this.firestore, 'adverts');
      const queryList = query(contactsCollection, where('status', '==', true), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(categories => categories as Advert[])
      );
    }

    getPackagesById(id: string): Observable<Package> {
      const document = doc(this.firestore, `packages-consultant/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Package;
        })
      );
    }

    getPackagesHandyById(id: string): Observable<Package> {
      const document = doc(this.firestore, `packages-handyman/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Package;
        })
      );
    }
  
  getForums(): Observable<Forum[]> {
    const contactsCollection = collection(this.firestore, 'categories');
    // this method returns a stream of documents mapped to their payload and id
    return collectionData(contactsCollection, {idField: 'id'})
    .pipe(
      map(categories => categories as Forum[])
    );
  }

  getInterest(): Observable<Forum[]> {
    const postCollection = collection(this.firestore, 'categories');
    return collectionData(postCollection, {idField: 'id'}) as Observable<Forum[]>;
    
  }

  getForumById(id: string): Observable<Forum> {
    const document = doc(this.firestore, `categories/${id}`);
    return docSnapshots(document)
    .pipe(
      map(doc => {
        const id = doc.id;
        const data = doc.data();
        return { id, ...data } as Forum;
      })
    );
  }

  updatePostBookmarks(post: Post){
    const postRef = doc(this.firestore, `posts/${post.id}`);
    return updateDoc(postRef, {bookmarks: post.bookmarks});
  }

  createForum(category: Forum): Promise<void> {
    const document = doc(collection(this.firestore, 'categories'));
    return setDoc(document, category);
  }

  createPost(post: Post): Promise<void> {
    const document = doc(collection(this.firestore, 'posts'));
    return setDoc(document, post);
  }

  getPostDetails(id: string): Observable<Post> {
    const document = doc(this.firestore, `posts/${id}`);
    return docSnapshots(document)
    .pipe(
      map(doc => {
        const id = doc.id;
        const data = doc.data();
        return { id, ...data } as Post;
      })
    );
  }

 

  updateForum(category: Forum): Promise<void> {
    const document = doc(this.firestore, 'categories', category?.id);
    const { id, ...data } = category; // we don't want to save the id inside the document
    return setDoc(document, data);
  }

  updateUser(user: User): Promise<void> {
    const document = doc(this.firestore, 'users', user?.id);
    const { id, ...data } = user; // we don't want to save the id inside the document
    return setDoc(document, data);
  }

  deleteForum(id: string): Promise<void> {
    const document = doc(this.firestore, 'categories', id);
    return deleteDoc(document);
  }


  getPostByUserId(id:any): Observable<Post[]>{
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('userId', '==', id));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(posts => posts as Post[])
    );
  }

  getFollowingPost(id: any, state: any): Observable<Post[]>{
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('userId', '==', id), where('state', '==', state));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>; 
  }


  getPosts(interest: Array<string>, state: any): Observable<Post[]> {
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('category', 'in', interest), where('state', '==', state), where('user', '==', 'other'), orderBy("created", "desc"), limit(20));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
  }

  getPostsCollabo(state: any): Observable<Post[]> {
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('state', '==', state), where('user', '==', 'collaborator'), orderBy("created", "desc"), limit(20));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
  }

  getMyBookmarkPosts(userId: any): Observable<Post[]> {
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('bookmarks', "array-contains", userId), orderBy("created", "desc"));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
    
  }


  loadMorePosts(interest: Array<string>, lastVisisble: any, state: any): Observable<Post[]> {
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('category', 'in', interest), where('state', '==', state),  orderBy("created", "desc"), startAfter(lastVisisble), limit(5));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
    
  }

  getPostsByCategory(id: any, state: any): Observable<Post[]> {
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('category', '==', id), where('state', '==', state), orderBy("created", "desc"));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
    
  }

  getPostByBookmakingIds(id: any): Observable<Post[]>{
    const postCollection = collection(this.firestore, 'posts');
    const queryList = query(postCollection, where('postId', '==', id));
    return collectionData(queryList, {idField: 'id'}) as Observable<Post[]>;
    
  }


  getUsers(state: any): Observable<User[]> {
    const userCollection = collection(this.firestore, 'users');
    const queryList = query(userCollection, where('state', '==', state));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(users => users as User[])
    );
  }

  getUsersList(): Observable<User[]> {
    const userCollection = collection(this.firestore, 'users');
    return collectionData(userCollection, {idField: 'id'})
    .pipe(
      map(users => users as User[])
    );
  }

  getNotifications(userId: any): Observable<Notifications[]>{
    const noteCollection = collection(this.firestore, 'users', userId, 'notifications');
    const queryList = query(noteCollection, orderBy('date'));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(notification => notification as Notifications[])
    );
  }


  getUnreadNotifications(userId: any): Observable<Notifications[]>{
    const noteCollection = collection(this.firestore, 'users', userId, 'notifications');
    const queryList = query(noteCollection, where('status', '==', 0));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(notification => notification as Notifications[])
    );
  }

 

  getUserById(id: string): Observable<User> {
    const document = doc(this.firestore, `users/${id}`);
    return docSnapshots(document)
    .pipe(
      map(doc => {
        const id = doc.id;
        const data = doc.data();
        return { id, ...data } as User;
      })
    );
  }

  getPostById(id: string): Observable<Post> {
    const document = doc(this.firestore, `posts/${id}`);
    return docSnapshots(document)
    .pipe(
      map(doc => {
        const id = doc.id;
        const data = doc.data();
        return { id, ...data } as Post
      })
    );
  }

 

  getPostComment(id: any): Observable<Comments> {
    const noteDocRef = doc(this.firestore, `posts/${id}`, 'comments');
    return docData(noteDocRef, { idField: 'id' }) as Observable<Comments>;
  }

  makeid(length: any) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getChats(chatId: any): Observable<Chat[]>{
    //query(citiesRef, orderBy("name"), limit(3));
    const chatsCollection = collection(this.firestore, 'chatroom', chatId, chatId);
    const queryList = query(chatsCollection, orderBy('timestamp'));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(chats => chats as Chat[])
    );
  }

  getChatsHistory(userId: any): Observable<ChatHistory[]>{
    //query(citiesRef, orderBy("name"), limit(3));
    const chatsCollection = collection(this.firestore, 'users', userId, 'chatlist');
    const queryList = query(chatsCollection, orderBy('timestamp'));
    return collectionData(queryList, {idField: 'id'})
    .pipe(
      map(chats => chats as ChatHistory[])
    );
  }

  
 
  getChatById(id: string, chatId: any): Observable<ChatHistory> {
    const document = doc(this.firestore, `users/${id}`, 'chatlist', chatId);
    return docSnapshots(document)
    .pipe(
      map(doc => {
         const id = doc.id;
        const data = doc.data();
        return { id, ...data } as ChatHistory;
      })
    );
  }

 

  createChat(chatData: any, userId: any, chatID: any): Promise<void> {
    const document = doc(this.firestore, `users/${userId}`, 'chatlist', chatID);
    return setDoc(document, chatData);
  }

 
    searchGif(searchWord: any) {
      return new Promise(resolve => {
        this.http.get(this.gifProvider.getSearchEndPoint(searchWord))
          .subscribe(data => {
            resolve(data);
          }, error => {
            console.log(error);
          });
      });
    }
  
    translateGif(translateWord: any) {
      return new Promise(resolve => {
        this.http.get(this.gifProvider.getTranslateEndPoint(translateWord))
          .subscribe(data => {
            resolve(data);
          }), (error: any) => {
          console.log(error);
        };
      })
    }
  
    getTrendingGifs() {
      return new Promise(resolve => {
        this.http.get(this.gifProvider.getTrendingEndPoint())
          .subscribe(data => {
            resolve(data);
          }, error => {
            console.log(error);
          })
      });
    }
  
    getRandomGif() {
      return new Promise(resolve => {
        this.http.get(this.gifProvider.getRandomEndPoint())
          .subscribe(data => {
            resolve(data);
          }, error => {
            console.log(error);
          })
      })
    }

    get(userId: any): Observable<User[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'users', userId, 'chatlist');
      const queryList = query(chatsCollection, orderBy('timestamp'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as User[])
      );
    }

    getUsersBookmark(userId: any): Observable<User[]> {
      const userCollection = collection(this.firestore, 'users', userId, 'bookmarks');
      return collectionData(userCollection, {idField: 'id'})
      .pipe(
        map(users => users as User[])
      );
    }

    getServiceByUserId(id:any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServiceCollabByUserId(id:any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services-collaborator');
      const queryList = query(postCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }


    getServiceByMap(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state), where('map', '==', true));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServiceByCatId(id:any, state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state), where('category', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }


    getServiceCat(): Observable<Servicecat[]>{
      const postCollection = collection(this.firestore, 'services-category');
      return collectionData(postCollection, {idField: 'id'})
      .pipe(
        map(posts => posts as Servicecat[])
      );
    }

    getServiceCatById(id: string): Observable<Servicecat> {
      const document = doc(this.firestore, `services-category/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Servicecat;
        })
      );
    }

    getHandyCatById(id: string): Observable<Servicecat> {
      const document = doc(this.firestore, `handyman-categories/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Servicecat;
        })
      );
    }

    getHandyCat(): Observable<Servicecat[]>{
      const postCollection = collection(this.firestore, 'handyman-categories');
      return collectionData(postCollection, {idField: 'id'})
      .pipe(
        map(posts => posts as Servicecat[])
      );
    }

    getHandyFeatureCat(): Observable<Servicecat[]>{
      const postCollection = collection(this.firestore, 'handyman-categories');
      const queryList = query(postCollection, orderBy("name"), limit(6));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Servicecat[])
      );
    }

    getHandyCatByCatId(id: any): Observable<Servicecat[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'handyman-categories');
      const queryList = query(chatsCollection, where('category', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Servicecat[])
      );
    }

    getServiceHandymanByUserId(id:any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'handyman-services');
      const queryList = query(postCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServiceHandymanByCatId(id:any, state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'handyman-services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state), where('category', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServicesHandyman(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'handyman-services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServicesHandymanOffline(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'handyman-services');
      const queryList = query(postCollection, where('online', '==', "false"), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServicesHandymanOnline(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'handyman-services');
      const queryList = query(postCollection, where('online', '==', "true"), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getBookingHandymanStatus(userId: any, status: any): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'handyman-services', userId, 'bookings');
      const queryList = query(chatsCollection, where('status', '==', status));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    getmyBookingHandymanStatus(userId: any, status: any): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'users', userId, 'bookings-handyman');
      const queryList = query(chatsCollection, where('status', '==', status));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    getReviewsBusiness(userId: any): Observable<Reviews[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'business', userId, 'reviews');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Reviews[])
      );
    }
  
    getReviewsHandyman(userId: any): Observable<Reviews[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'handyman-services', userId, 'reviews');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Reviews[])
      );
    }

    getBookingHandyman(userId: any): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'handyman-services', userId, 'bookings');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

   

    getMyBookingHandyman(userId: any): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'users', userId, 'bookings-handyman');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    getServiceHandymanById(id: string): Observable<Service> {
      const document = doc(this.firestore, `handyman-services/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Service;
        })
      );
    }
  

    //orderBy("created", "desc"), limit(20)

    getServicesFeatured(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state), where('available', '!=', undefined));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServices(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServicesOnline(state: any): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('able', '==', true), where('state', '==', state), where('online', '==', "true"));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    getServicesOffline(state): Observable<Service[]>{
      const postCollection = collection(this.firestore, 'services');
      const queryList = query(postCollection, where('online', '==', "false"), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Service[])
      );
    }

    

    getBookingStatus(userId, status): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'services', userId, 'bookings');
      const queryList = query(chatsCollection, where('status', '==', status));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    search(query){
      const url = `https://nominatim.openstreetmap.org/?addressdetails=1&namedetails=1&extratags=1&q=`;
      return this.http.get(url + query + '&format=json&limit=10')
      .pipe(map((res) => {
        return res
      }))
    }

    getmyBookingStatus(userId, status): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'users', userId, 'bookings');
      const queryList = query(chatsCollection, where('status', '==', status));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }
  
    getReviews(userId): Observable<Reviews[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'services', userId, 'reviews');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Reviews[])
      );
    }

    getBooking(userId): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'services', userId, 'bookings');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    getBookingById(id: string, bookingId): Observable<Booking> {
      const document = doc(this.firestore, 'services', id, 'bookings', bookingId);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Booking;
        })
      );
    }

    getMyBookingById(id: string, bookingId): Observable<Booking> {
      const document = doc(this.firestore, 'users', id, 'bookings', bookingId);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Booking;
        })
      );
    }
  
  

    getMyBooking(userId): Observable<Booking[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'users', userId, 'bookings');
      const queryList = query(chatsCollection, orderBy('time'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Booking[])
      );
    }

    getServiceById(id: string): Observable<Service> {
      const document = doc(this.firestore, `services/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Service;
        })
      );
    }


    getBusinessById(id): Observable<Business> {
      const document = doc(this.firestore, `business/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
           const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Business;
        })
      );
    }


    getBusinessByUserId(id): Observable<Business[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'business');
      const queryList = query(chatsCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Business[])
      );
    }

    
    getBusinessByState(state): Observable<Business[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'business');
      const queryList = query(chatsCollection, where('state', '==', state), where('status', '==', true));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Business[])
      );
    }

    getBusinessByFilter(state, type, category): Observable<Business[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'business');
      const queryList = query(chatsCollection, where('state', '==', state), where('status', '==', true), where('type', '==', type), where('category', '==', category));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Business[])
      );
    }

    getBusinessByStatus(state): Observable<Business[]>{
      //query(citiesRef, orderBy("name"), limit(3));
      const chatsCollection = collection(this.firestore, 'business');
      const queryList = query(chatsCollection, where('status', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(chats => chats as Business[])
      );
    }



    getNewBookingConsult(serviceid, id): Observable<Booking[]>{
      const postCollection = collection(this.firestore, 'consultant-bookings');
      const queryList = query(postCollection, where('serviceid', '==', serviceid), where('consultantid', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Booking[])
      );
    }


    getNewBookingUser(id): Observable<Booking[]>{
      const postCollection = collection(this.firestore, 'consultant-bookings');
      const queryList = query(postCollection, where('userId', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Booking[])
      );
    }

    getNewBookingUserStatus(id, status): Observable<Booking[]>{
      const postCollection = collection(this.firestore, 'consultant-bookings');
      const queryList = query(postCollection, where('userId', '==', id), where('status', '==', status));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Booking[])
      );
    }

    getNewBookingById(id: string): Observable<Booking> {
      const document = doc(this.firestore, `consultant-bookings/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Booking;
        })
      );
    }
   
    sendNotificationOnesignal(msg: string, title: string, id: string) {
      const body = {
        app_id: environment.onesignal.appId,
        include_player_ids: [id],
        headings: { en: title },
        contents: { en: msg },
        data: { task: msg }
      };
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `Basic ${environment.onesignal.restKey}`)
      };
      return this.http.post('https://onesignal.com/api/v1/notifications', body, header)
      /*.subscribe(new_data => {
        console.log(new_data)
      }, error => {
        console.log(error);
      });*/
    }


    sendNativeNote(msg: string, title: string, id: string){
      this.httpPlugin.setDataSerializer('json');
      this.httpPlugin.sendRequest('https://onesignal.com/api/v1/notifications', 
         {
          method: 'post', 
          data: { 
            app_id: environment.onesignal.appId,
            include_player_ids: [id],
            headings: { en: title },
            contents: { en: msg },
            data: { task: msg }
          },
          headers: { Authorization: `Basic ${environment.onesignal.restKey}` },
          timeout: 5000
        }
      ).then( async (response) =>  {
        // prints 200
        console.log(response.status);
      }).catch(async (response) => {
        // prints 403
        console.log(response.status);
        // prints Permission denied
        console.log(response.error);
      })
    }


    sendNotificationAPI(to: string, msg: string, subject: string, type: string, lang: string) {
      const body = {
        to: to,
        subject: subject,
        msg: msg,
        type: type,
        languageType: lang
      };
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      };
      return this.http.post('https://api.inmigrausa.app/api/notification', body, header)
      /*.subscribe(new_data => {
        console.log(new_data)
      }, error => {
        console.log(error);
      });*/
    }

    sendNativeAPI(to: string, msg: string, subject: string, type: string, lang: string){
      this.httpPlugin.setDataSerializer('json');
      this.httpPlugin.sendRequest('https://api.inmigrausa.app/api/notification', 
         {
          method: 'post', 
          data: { 
            to: to,
            subject: subject,
            msg: msg,
            type: type,
            languageType: lang
          },
          //headers: { Authorization: `Basic ${environment.onesignal.restKey}` },
          timeout: 5000
        }
      ).then( async (response) =>  {
        // prints 200
        console.log(response.status);
      }).catch(async (response) => {
        // prints 403
        console.log(response.status);
        // prints Permission denied
        console.log(response.error);
      })
    }

    sendSMS(phone: string, code) {
      const body = {
        code: code,
      };
      const header = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      };
      return this.http.post(`https://api.inmigrausa.app/api/get_code/${phone}`, body, header)
      /*.subscribe(new_data => {
        console.log(new_data)
      }, error => {
        console.log(error);
      });*/
    }

    sendNativeSMS(phone: string){
      this.httpPlugin.setDataSerializer('json');
      this.httpPlugin.sendRequest('https://api.inmigrausa.app/api/get_code/:tel', 
         {
          method: 'get', 
          data: { 
            tel: phone,
          },
          //headers: { Authorization: `Basic ${environment.onesignal.restKey}` },
          timeout: 5000
        }
      ).then( async (response) =>  {
        // prints 200
        console.log(response.status);
      }).catch(async (response) => {
        // prints 403
        console.log(response.status);
        // prints Permission denied
        console.log(response.error);
      })
    }

    //getServices(){}

    getQuizCategories(): Observable<Category[]>{
      const postCollection = collection(this.firestore, 'quiz-category');
     // const queryList = query(postCollection, where('consultantid', '==', id), where('status', '==', status));
      return collectionData(postCollection, {idField: 'id'})
      .pipe(
        map(posts => posts as Category[])
      );
    }

    getQuizCatById(id: string): Observable<Category> {
      const document = doc(this.firestore, `quiz-category/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Category;
        })
      );
      }

    getQuiz(): Observable<Quiz[]>{
      const postCollection = collection(this.firestore, 'quiz');
     // const queryList = query(postCollection, where('consultantid', '==', id), where('status', '==', status));
      return collectionData(postCollection, {idField: 'id'})
      .pipe(
        map(posts => posts as Quiz[])
      );
    }


    getQuizByCat(id): Observable<Quiz[]>{
      const postCollection = collection(this.firestore, 'quiz');
      const queryList = query(postCollection, where('category', '==', id));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Quiz[])
      );
    }

 

    getQuizs(): Observable<Quiz[]>{
      const postCollection = collection(this.firestore, 'quiz');
      //const queryList = query(postCollection, where('category', '==', id));
      return collectionData(postCollection, {idField: 'id'})
      .pipe(
        map(posts => posts as Quiz[])
      );
    }

    getQuizByCatState(id, state): Observable<Quiz[]>{
      const postCollection = collection(this.firestore, 'quiz');
      const queryList = query(postCollection, where('category', '==', id), where('state', '==', state));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Quiz[])
      );
    }

    getQuizByCatStatePaid(id, state): Observable<Quiz[]>{
      const postCollection = collection(this.firestore, 'quiz');
      const queryList = query(postCollection, where('category', '==', id), where('state', '==', state), where('status', '==', 'paid'));
      return collectionData(queryList, {idField: 'id'})
      .pipe(
        map(posts => posts as Quiz[])
      );
    }


    getQuizById(id: string): Observable<Quiz> {
      const document = doc(this.firestore, `quiz/${id}`);
      return docSnapshots(document)
      .pipe(
        map(doc => {
          const id = doc.id;
          const data = doc.data();
          return { id, ...data } as Quiz;
        })
      );
    }

getSettings(id): Observable<Setting> {
  const document = doc(this.firestore, `settings/${id}`);
  return docSnapshots(document)
  .pipe(
    map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, ...data } as Setting
    })
  );
}


getNewBookingHandyman(serviceid, id): Observable<Booking[]>{
  const postCollection = collection(this.firestore, 'handyman-bookings');
  const queryList = query(postCollection, where('serviceid', '==', serviceid), where('consultantid', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Booking[])
  );
}

getNewBookingConsultStatus(serviceid, id, status): Observable<Booking[]>{
  const postCollection = collection(this.firestore, 'consultant-bookings');
  const queryList = query(postCollection, where('serviceid', '==', serviceid), where('consultantid', '==', id), where('status', '==', status));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Booking[])
  );
}

getNewBookingHandymanStatus(serviceid, id, status): Observable<Booking[]>{
  const postCollection = collection(this.firestore, 'handyman-bookings');
  const queryList = query(postCollection, where('serviceid', '==', serviceid), where('consultantid', '==', id), where('status', '==', status));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Booking[])
  );
}

getNewBookingHandymanUser(id): Observable<Booking[]>{
  const postCollection = collection(this.firestore, 'handyman-bookings');
  const queryList = query(postCollection, where('userId', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Booking[])
  );
}

getNewBookingHandymanUserStatus(id, status): Observable<Booking[]>{
  const postCollection = collection(this.firestore, 'handyman-bookings');
  const queryList = query(postCollection, where('userId', '==', id), where('status', '==', status));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Booking[])
  );
}

getNewBookingHandymanById(id: string): Observable<Booking> {
  const document = doc(this.firestore, `handyman-bookings/${id}`);
  return docSnapshots(document)
  .pipe(
    map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, ...data } as Booking;
    })
  );
}


getCollabo(state): Observable<User[]>{
  const postCollection = collection(this.firestore, 'users');
  const queryList = query(postCollection, where('state', '==', state), where('type', '==', 'collaborator'), orderBy("displayName"), limit(20));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as User[])
  );
}

//orderBy("created", "desc"), limit(5)
getWithdrawConfig(id: string): Observable<Config> {
  const document = doc(this.firestore, `withdraw-config/${id}`);
  return docSnapshots(document)
  .pipe(
    map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, ...data } as Config;
    })
  );
}

getResolutionById(id: string): Observable<Resolution> {
  const document = doc(this.firestore, `resolutions/${id}`);
  return docSnapshots(document)
  .pipe(
    map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, ...data } as Resolution;
    })
  );
}


getSearchUsers(): Observable<User[]> {
  const postCollection = collection(this.firestore, 'users');
  return collectionData(postCollection, {idField: 'id'}) as Observable<User[]>;
  
}


getUserFind(): Observable<User[]>{
  const postCollection = collection(this.firestore, 'users');
  return collectionData(postCollection, {idField: 'id'})
  .pipe(
    map(posts => posts as User[])
  );
}


getConsultWithdraw(id:any): Observable<Withdraw[]>{
  const postCollection = collection(this.firestore, 'widraw_consultant');
  const queryList = query(postCollection, where('userId', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Withdraw[])
  );
}


getServicetWithdraw(id:any): Observable<Withdraw[]>{
  const postCollection = collection(this.firestore, 'widraw_service');
  const queryList = query(postCollection, where('userId', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Withdraw[])
  );
}


getOrderById(id: string): Observable<Orders> {
  const document = doc(this.firestore, `item-orders/${id}`);
  return docSnapshots(document)
  .pipe(
    map(doc => {
      const id = doc.id;
      const data = doc.data();
      return { id, ...data } as Orders;
    })
  );
}

getMyOrders(id:any): Observable<Orders[]>{
  const postCollection = collection(this.firestore, 'item-orders');
  const queryList = query(postCollection, where('userId', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Orders[])
  );
}


getOrders(id:any): Observable<Orders[]>{
  const postCollection = collection(this.firestore, 'item-orders');
  const queryList = query(postCollection, where('ownerId', '==', id));
  return collectionData(queryList, {idField: 'id'})
  .pipe(
    map(posts => posts as Orders[])
  );
}



}