// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  glassfyKey: '1dc8864408a54d1c8ce0c4b27c51cbd7',
  firebaseConfig: {
    apiKey: "AIzaSyDkKVVTT3PcuBgZoUDI-iOKNIgTY3c65_g",
    authDomain: "comunidade-brasileira.firebaseapp.com",
    projectId: "comunidade-brasileira",
    storageBucket: "comunidade-brasileira.appspot.com",
    messagingSenderId: "498057763684",
    appId: "1:498057763684:ios:699bf790f3116b88b53871",
    measurementId: "G-32E49PLP96"
   },
   fcmtoken: {
    tokens: 'AAAAc_aOW2Q:APA91bH7IyE9Y22-UK46U559i8RusdONVpoOBrkE26nYi-3jjxvTxyMPRL-JcBhSuqshcQYLBbvS66QILYn_Ne-8g1Q-gPfSS5bxPRnVQiCH7BhE-vqHXmiifRUzGAAgH4q7Qla1pr9-',
  },
   stripe: {
    sk: 'sk_test_51M1a3FL7H3TGzFRCAX4caP3rbBvhkYmwkwOG2MbBy6d1RDHDVsk7iKFT8yTxVb8gNMrUdv6M3OrqRuMlhyPQdJrc00UElzuaAf'
  },
  onesignal: {
    appId: '7b25460b-6038-4e1f-bc05-5e61f5372bc8',
    googleProjectNumber: '498057763684',
    restKey: 'ZWJhYjdiMjQtMmExYy00MjVlLTkzODgtMDJkMzc0YWYxZTMw'
  },
  apiKey: 'AIzaSyAIig4_oHWHpNUpym1K5oG-qqourKIM0ZI',
  baseUrlApi: 'https://inmigrausa.herokuapp.com/api',
  notification_url: 'https://fcm.googleapis.com/fcm/send',
  API_URL: 'https://inmigrausa.com',
  key: 'MTI2ODFjZjEtZDBlZC00ODNjLWI4MWMtOTNjNTEyZjQ1OGY2',

  
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
