import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';
import { provideAuth, getAuth, initializeAuth, indexedDBLocalPersistence} from '@angular/fire/auth';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

// Translation Modules
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_CONFIG, BaseAppConfig } from './app.config';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';



/*export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.API_URL + './assets/i18n/','.json');
}*/


/*export function customTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/

/*export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}*/

import { QuillModule, QuillModules } from 'ngx-quill';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import Quill from 'quill';

//import { Linkify, Options } from 'quill-linkify';

/*Quill.register('modules/linkify', Linkify);

const linkifyOptions: Options = {

  url: /(https?:\/\/|www\.)[\w-\.]+\.[\w-\.]+(\/([\S]+)?)?/i, // Use custom regexp
  mail: true, // Use default regexp
  phoneNumber: false, // Disable text auto link
};*/


import MagicUrl from 'quill-magic-url' 
import { TokenInterceptor } from './providers/inerceptor';

Quill.register('modules/magicUrl', MagicUrl)
Quill.register(QuillDeltaToHtmlConverter, true)


const modules: QuillModules = {
  magicUrl: {
    // Regex used to check URLs during typing
    urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
    // Regex used to check URLs on paste
    globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
    mailRegularExpression: /([\w-\.]+@[\w-\.]+\.[\w-\.]+)/gi
  },
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1}, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],

    [{ header: [1, 2 ,3, 4 ,5, 6, false] }],

    [{ color: [] }, { background: [] }],


    [{ font: [] }],

    [{ align: [] }],

    ['link'],

   
  ],
  
};



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    //IonicModule.forRoot({mode:'ios'}), 
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    QuillModule.forRoot({
      format: 'html',
      modules,
      placeholder: 'Description..',

    }),
    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebaseConfig);
        if (Capacitor.isNativePlatform) {
          initializeAuth(app, {
              persistence: indexedDBLocalPersistence
          });
      }
        return app;
      }),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideStorage(() => getStorage()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    HTTP, 
    InAppPurchase2,
    Diagnostic,
    BarcodeScanner,
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
