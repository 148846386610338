import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languageUrl = 'https://api.inmigrausa.app/api/languages/';

  constructor(private http: HttpClient) {}

  getLanguageDatas(languageCode: string) {
    const url = this.languageUrl + languageCode + '.json';
    return this.http.get(url);
  }

  async getLanguageDatap(languageCode: string) {
    try {
      const url = this.languageUrl + languageCode + '.json';
      const response = await axios.get(url);
      const translationData = response.data;
      return translationData;
    } catch (error) {
      console.error(error);
    }
  }

  async getLanguageData(languageCode: string) {
    const options = {
      method: 'GET',
      url: `https://api.inmigrausa.app/api/languages/${languageCode}`,
      data: {
        "code": "965044"
      },
      headers: {
        "content-type": "application/json"
      }
    };
    try {
        const response = await axios.request(options);
        let newData = response.data;
        console.log(newData)
        return newData
        
    } catch (error) {
      console.log(error)
       //this.isLogin = false;
     // this.displayAlertMessage(`Either we couldn't find your user or there was a problem with the password`);
     }
    }
}