import { InjectionToken } from "@angular/core";
import { Constants } from "./models/constants.models";


export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export interface AppConfig {
	availableLanguages: Array<{ code: string, name: string, flag: string }>
} 

export const BaseAppConfig: AppConfig = {
    availableLanguages: [{
        code: 'en',
        name: 'English',
        flag: 'gb',
    }, {
        code: 'es',
        name: 'Spanish',
        flag: 'es',
    },{
        code: 'pt',
        name: 'Portugese',
        flag: 'pt'
    }
],
};